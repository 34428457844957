import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import scrollTo from 'gatsby-plugin-smoothscroll';
// import heroImagePNG from "images/blonde_clients.jpg";
import heroImagePNG from "images/website-hero-image.png";


const useStyles = makeStyles(theme => ({
  image: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));

const Hero = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container justify="space-between" spacing={isMd ? 4 : 2}  alignItems="center">
        <Grid item xs={12} md={6} data-aos={'fade-up'}>
          <SectionHeader
            title={
              <span>
                Welcome to{' '}
                <Typography component="span" variant="inherit" color="primary">Fackl.</Typography>
                <br />
                <span>The future of calling.</span>
              </span>
            }
            subtitle="Send and receive calls with multiple office numbers directly on your phone. No app or internet required."
            ctaGroup={[
              <Button variant="contained" color="primary" size="large" href="https://app.fackl.com">
                Sign up
              </Button>,
              <Button variant="outlined" color="primary" size="large" onClick={() => scrollTo("#pricing" )}>
                see pricing
              </Button>,
            ]}
            align={isMd ? 'left' : 'center'}
            disableGutter
            titleVariant="h3"
          />
        </Grid>
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            // src="https://assets.maccarianagency.com/the-front/illustrations/mind-map.svg"
            // src={heroImage}
            src={heroImagePNG}
            alt="TheFront Company"
            className={classes.image}
          />
        </Grid>
      </Grid>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
