import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, Button, Card } from '@material-ui/core';
import { LearnMoreLink, Icon } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { CardPricingStandard } from 'components/organisms';
import clsx from "clsx";



const useStyles = makeStyles(theme => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionContainer: {
    backgroundColor: theme.palette.primary.light,
    paddingBottom: theme.spacing(20),
    borderRadius: '0 0 100% 0',
  },
  pricingSectionContainer: {
    backgroundColor: theme.palette.primary.main,
    paddingBottom: theme.spacing(20),
    borderRadius: '0 0 100% 0',
  },
  textWhite: {
    color: 'white',
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  toggleContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(0, 2),
  },
  toggleButtonGroup: {
    background: 'transparent',
  },
  toggleButton: {
    background: 'transparent',
    border: '1px solid white',
    padding: theme.spacing(1, 5),
  },
  toggleButtonActive: {
    backgroundColor: 'white !important',
  },
  toggleTitle: {
    textTransform: 'capitalize',
  },
  toggleTitleActive: {
    color: theme.palette.primary.main,
  },
  pricingContainer: {
    position: 'relative',
    marginTop: theme.spacing(-20),
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  cardPricing: {
    '& .countup-number__count-wrapper': {
      textAlign: 'left',
      marginBottom: 0,
      fontWeight: 'bold',

    },
  },
  featureStyles: {
    textAlign: 'center',
  }
}));



const Pricings = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  // function trackInitiateCheckout(data) {
  //   if (typeof window !== "undefined") {
  //     if (window.fbq != null) {
  //       // window.fbq('track', 'Lead', {currency: "USD", value: 9.99});
  //       window.fbq('track', 'InitiateCheckout', data)
  //     }
  //   }
  // }




  return (
    <div id="pricing" className={className} {...rest}>
      <SectionHeader
            title="Smart Pricing"
            subtitle="The perfect tool for business owners and remote workers. Pay a low monthly subscription then only for what you use."
            titleProps={{
              // className: clsx(classes.textWhite, classes.fontWeightBold),
              className: clsx( classes.fontWeightBold),
              // variant: 'h2',
            }}
            subtitleProps={{
              // className: classes.textWhite,
            }}
            data-aos="fade-up"
          />
      <Grid container spacing={isMd ? 4 : 2} justify="center">
              {data.map((item, index) => (
                <Grid item xs={12} md={3} data-aos="fade-up" key={index}>

                  <CardPricingStandard
                    variant="outlined"
                    withShadow={item.isHighlighted ? true : false}
                    title={item.title}
                    liftUp
                    subtitle={item.subtitle}
                    monthlyPrice={
                      item.monthly ?
                      (<Typography variant="h3" color="textPrimary">
                        ${item.monthly}
                          <Typography variant="h6" color="textPrimary">
                            /month
                          </Typography>
                      </Typography>) : null
                    }
                    // variableCost={
                    //   <Typography variant="h4" color="textPrimary">
                    //     {item.variable}¢
                    //       <Typography variant="subtitle2" color="textPrimary">
                    //         /minute of calling
                    //       </Typography>
                    //   </Typography>
                    // }
                    features={item.features}
                    featureCheckComponent={
                      <Icon
                        fontIconClass="far fa-check-circle"
                        fontIconColor={theme.palette.primary.main}
                      />
                    }
                    cta={ item.id === "enterprise" ?
                        (<Button
                        color="primary"
                        variant={item.isHighlighted ? 'contained' : 'outlined'}
                        fullWidth
                        size="large"
                        href="mailto: support@fackl.com"
                      >
                        Contact
                      </Button>) :
                      (<Button
                          color="primary"
                          variant={item.isHighlighted ? 'contained' : 'outlined'}
                          fullWidth
                          size="large"
                          // onClick={trackInitiateCheckout({
                          //   value: item.monthly,
                          //   currency: "USD"
                          // })}
                          href="https://app.fackl.com"
                      >
                        Select
                      </Button>)
                    }
                    // disclaimer={item.disclaimer}
                    className={classes.cardPricing}
                  />
                </Grid>


              ))}
              {/*<CustomBusiness data={proCustom}/>*/}


            </Grid>
    </div>
  );
};

Pricings.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Pricings;
